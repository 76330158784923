import * as BaseDropdownMenu from "@radix-ui/react-dropdown-menu";
import { PropsWithChildren, ReactNode } from "react";

interface DropdownMenuProps
    extends PropsWithChildren<BaseDropdownMenu.DropdownMenuContentProps> {
    trigger: ReactNode;
}

const CheckboxItem = BaseDropdownMenu.CheckboxItem;
const Item = BaseDropdownMenu.DropdownMenuItem;

export { CheckboxItem, Item };

export default function DropdownMenu({
    trigger,
    children,
    ...props
}: DropdownMenuProps): JSX.Element {
    return (
        <BaseDropdownMenu.Root>
            <BaseDropdownMenu.Trigger asChild>
                {trigger}
            </BaseDropdownMenu.Trigger>
            <BaseDropdownMenu.Portal>
                <BaseDropdownMenu.Content {...props}>
                    {children}
                </BaseDropdownMenu.Content>
            </BaseDropdownMenu.Portal>
        </BaseDropdownMenu.Root>
    );
}
