import {
    CheckIcon,
    ChevronDownIcon,
    Cross2Icon,
    ExclamationTriangleIcon,
    HeartFilledIcon,
    HeartIcon,
    HomeIcon,
    ImageIcon,
    LoopIcon,
    QuestionMarkCircledIcon,
    MagnifyingGlassIcon,
    SpeakerLoudIcon,
    TwitterLogoIcon,
    VideoIcon,
    BoxIcon,
    ExitIcon,
} from "@radix-ui/react-icons";
import { IconProps as BaseIconProps } from "@radix-ui/react-icons/dist/types";
import ShareIcon from "./ShareIcon";

type IconName =
    | "audio"
    | "box"
    | "check"
    | "chevron-down"
    | "cross"
    | "error"
    | "exit"
    | "heart"
    | "heart-filled"
    | "home"
    | "image"
    | "magnifying-glass"
    | "question-mark-circled"
    | "remint"
    | "share"
    | "twitter"
    | "video";

function getIconClass(name: IconName) {
    switch (name) {
        case "check":
            return CheckIcon;
        case "image":
            return ImageIcon;
        case "chevron-down":
            return ChevronDownIcon;
        case "cross":
            return Cross2Icon;
        case "error":
            return ExclamationTriangleIcon;
        case "heart":
            return HeartIcon;
        case "heart-filled":
            return HeartFilledIcon;
        case "home":
            return HomeIcon;
        case "remint":
            return LoopIcon;
        case "share":
            return ShareIcon;
        case "twitter":
            return TwitterLogoIcon;
        case "audio":
            return SpeakerLoudIcon;
        case "video":
            return VideoIcon;
        case "question-mark-circled":
            return QuestionMarkCircledIcon;
        case "magnifying-glass":
            return MagnifyingGlassIcon;
        case "box":
            return BoxIcon;
        case "exit":
            return ExitIcon;
    }
}

interface IconProps extends BaseIconProps {
    name: IconName;
}

export default function Icon({ name, ...props }: IconProps): JSX.Element {
    const IconClass = getIconClass(name);

    return <IconClass {...props} />;
}
