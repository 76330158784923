interface DividerProps {
    className?: string;
}

export default function Divider({ className }: DividerProps): JSX.Element {
    const baseClassName = `border-0 border-gray-300 border-b border-l`;
    const finalClassName = baseClassName + (className ? ` ${className}` : "");

    return <hr className={finalClassName} />;
}
