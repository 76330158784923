import { clsx } from "clsx";
import { Oval } from "react-loader-spinner";

type Size = "extrasmall" | "small" | "medium" | "large";
type Variant = "light" | "dark" | "loading";

interface LoaderProps {
    size?: Size;
    variant?: Variant;
    className?: string;
}

function getSize(size: Size): number {
    switch (size) {
        case "extrasmall":
            return 12;
        case "small":
            return 16;
        case "medium":
            return 30;
        case "large":
            return 60;
    }
}

interface Colors {
    color: string;
    secondaryColor: string;
}

function getColors(variant: Variant): Colors {
    switch (variant) {
        case "light":
            return {
                color: "rgba(255, 255, 255, 0.7)",
                secondaryColor: "rgba(255, 255, 255, 0.9)",
            };
        case "dark":
            return {
                color: "rgba(0, 0, 0, 0.3)",
                secondaryColor: "rgba(0, 0, 0, 0.1)",
            };
        case "loading":
            return {
                // warning-700
                color: "rgba(181, 71, 8, 1)",
                secondaryColor: "rgba(181, 71, 8, 0.4)",
            };
    }
}

export default function Loader({
    size = "extrasmall",
    variant = "dark",
    className,
}: LoaderProps): JSX.Element {
    const strokeWidth = 4;

    const loaderSize = getSize(size);

    return (
        <Oval
            ariaLabel="loading-indicator"
            height={loaderSize}
            width={loaderSize}
            strokeWidth={strokeWidth}
            wrapperClass={clsx("!p-0", className)}
            {...getColors(variant)}
        />
    );
}
