import {
    addressValidator,
    bigIntStringValidator,
} from "lib/zod/validators.mjs";
import { useRouter } from "next/router";
import { ChangeEvent, KeyboardEvent, useCallback, useState } from "react";
import { ChainId } from "server/services/ethereum/constants";
import { SearchForm } from "./SearchForm";
import { SearchProps } from "./types";

const openSeaUrlRegex = new RegExp(
    "https:\\/\\/opensea\\.io\\/assets\\/ethereum\\/(.*)\\/(.*)"
);

export default function NftUrlSearch({ onClose }: SearchProps): JSX.Element {
    const router = useRouter();
    const [value, setValue] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const regexResult = openSeaUrlRegex.exec(value);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value.trim();
            if (newValue !== value) {
                setValue(newValue);
                setError("");
            }
        },
        [value]
    );

    const handleKeyUp = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (isLoading) return;
            if (event.key === "Escape") onClose();
        },
        [onClose, isLoading]
    );

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();

            if (isLoading) return;

            if (!regexResult) {
                return setError("Link to NFT is incorrectly formatted");
            }

            const [, contractAddress, tokenId] = regexResult;

            if (!addressValidator.safeParse(contractAddress).success) {
                return setError("NFT contract address is invalid");
            }

            if (!bigIntStringValidator.safeParse(tokenId).success) {
                return setError("Token ID is invalid");
            }

            setIsLoading(true);
            void router.push(
                `/nft/${ChainId.Mainnet}/${contractAddress}/${tokenId}`
            );
            onClose();
        },
        [router, isLoading, regexResult, onClose]
    );

    return (
        <SearchForm
            value={value}
            onChange={handleChange}
            label="OpenSea URL of an NFT"
            onSubmit={handleSubmit}
            placeholder="https://opensea.io/assets/ethereum/0x..."
            error={error}
            isLoading={isLoading}
            handleKeyUp={handleKeyUp}
            disabled={isLoading || !regexResult}
        />
    );
}
