import { z } from "zod";
import { getAddress, isHex } from "viem";

export const addressValidator = z
    .string()
    .transform((val, ctx) => {
        try {
            return getAddress(val.toLowerCase());
        } catch {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "string provided is not an address",
            });
            return z.NEVER;
        }
    })
    .brand("Address");

export const transactionHashValidator = z
    .string()
    .transform((val, ctx) => {
        if (!isHex(val)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "string provided is not a hex string",
            });
            return z.NEVER;
        }

        if (val.length !== 66) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "string provided is incorrect length",
            });
            return z.NEVER;
        }

        return val;
    })
    .brand("TransactionHash");

export const jsNumberStringValidator = z
    .string()
    .pipe(z.coerce.number().safe());

export const bigIntStringValidator = z.string().pipe(z.coerce.bigint());

export const numberDateValidator = z
    .union([z.number(), z.bigint()])
    .pipe(z.coerce.number().safe())
    .pipe(z.coerce.date());
