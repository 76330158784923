import { PaperPlaneIcon } from "@radix-ui/react-icons";
import { IconProps } from "@radix-ui/react-icons/dist/types";

export default function ShareIcon(props: IconProps) {
    return (
        // Flex container to ensure that rotated element only has the icon's size
        <div className="flex">
            <div className="-rotate-[24deg] -mt-1">
                <PaperPlaneIcon {...props} />
            </div>
        </div>
    );
}
