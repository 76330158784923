import { track } from "client/lib/analytics";
import Button from "components/button/Button";
import Loader from "components/loader";
import {
    ChangeEventHandler,
    FormEventHandler,
    KeyboardEventHandler,
    useCallback,
} from "react";

interface SearchFormProps {
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    label: string;
    onSubmit: FormEventHandler;
    placeholder: string;
    error: string | null;
    isLoading: boolean;
    handleKeyUp: KeyboardEventHandler<HTMLInputElement>;
    disabled?: boolean;
}

export function SearchForm({
    value,
    onChange,
    label,
    onSubmit,
    placeholder,
    error,
    disabled,
    isLoading,
    handleKeyUp,
}: SearchFormProps) {
    const errorClassName = error ? "animate-shake" : "";
    const finalClassName =
        "w-full h-full flex-1 flex flex-row items-center gap-3 truncate pl-5 " +
        (errorClassName ? ` ${errorClassName}` : "");

    const handleSubmit = useCallback(
        (event) => {
            track("search:submit", {
                value,
                label,
            });

            onSubmit(event);
        },
        [onSubmit, value, label]
    );

    return (
        <form className={finalClassName} onSubmit={handleSubmit}>
            <div className="flex-1 flex flex-col gap-1 justify-center">
                <div className=" text-xs font-medium text-gray-500">
                    {label}
                </div>
                <input
                    className="text-sm font-medium placeholder-gray-400 focus:outline-none"
                    value={value}
                    onChange={onChange}
                    onKeyUp={handleKeyUp}
                    placeholder={placeholder}
                    autoFocus
                    spellCheck="false"
                    autoCapitalize="false"
                    autoComplete="false"
                    autoCorrect="false"
                />
                {error && <div className="text-error-500 text-xs">{error}</div>}
            </div>
            <Button
                type="submit"
                size="small"
                disabled={disabled}
                className="w-12 md:w-20"
            >
                {isLoading ? <Loader variant="light" /> : "Go"}
            </Button>
        </form>
    );
}
