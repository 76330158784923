import { useState } from "react";
import AccountSearch from "./AccountSearch";
import NftIdSearch from "./NftIdSearch";
import NftUrlSearch from "./NftUrlSearch";
import SearchTypeSelector from "./SearchTypeSelector";
import { SearchProps, SearchType } from "./types";

export default function SearchBar({ onClose }: SearchProps): JSX.Element {
    const [searchType, setSearchType] = useState(SearchType.ACCOUNT);

    return (
        <div className="h-[72px] flex flex-row items-center bg-white rounded-full pr-5 md:pr-10 motion-safe:animate-size-opacity-in w-full">
            <SearchTypeSelector
                searchType={searchType}
                setSearchType={setSearchType}
            />
            {searchType === SearchType.ACCOUNT && (
                <AccountSearch onClose={onClose} />
            )}
            {searchType === SearchType.NFT_URL && (
                <NftUrlSearch onClose={onClose} />
            )}
            {searchType === SearchType.NFT_ID && (
                <NftIdSearch onClose={onClose} />
            )}
        </div>
    );
}
