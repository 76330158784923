import { clsx } from "clsx";
import Button from "components/button/Button";
import Divider from "components/Divider";
import Loader from "components/loader";
import {
    addressValidator,
    bigIntStringValidator,
} from "lib/zod/validators.mjs";
import { useRouter } from "next/router";
import {
    ChangeEvent,
    HTMLProps,
    KeyboardEvent,
    useCallback,
    useState,
} from "react";
import { ChainId } from "server/services/ethereum/constants";
import { SearchProps } from "./types";

export default function NftIdSearch({ onClose }: SearchProps): JSX.Element {
    const router = useRouter();

    const [address, setAddress] = useState("");
    const [tokenId, setTokenId] = useState("");

    const [addressError, setAddressError] = useState(false);
    const [tokenIdError, setTokenIdError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleChangeContractAddress = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newAddress = event.target.value.trim();
            if (newAddress !== address) {
                setAddress(newAddress);
                setAddressError(false);
                setTokenIdError(false);
            }
        },
        [address]
    );

    const handleChangeTokenId = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newTokenId = event.target.value.trim();
            if (newTokenId !== tokenId) {
                setTokenId(newTokenId);
                setAddressError(false);
                setTokenIdError(false);
            }
        },
        [tokenId]
    );

    const handleKeyUp = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (isLoading) return;
            if (event.key === "Escape") onClose();
        },
        [onClose, isLoading]
    );

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();

            if (isLoading) return;

            if (!addressValidator.safeParse(address).success) {
                return setAddressError(true);
            }

            if (!bigIntStringValidator.safeParse(tokenId).success) {
                return setTokenIdError(true);
            }

            setIsLoading(true);
            void router.push(`/nft/${ChainId.Mainnet}/${address}/${tokenId}`);
            onClose();
        },
        [router, address, tokenId, isLoading, onClose]
    );

    return (
        <form
            className={clsx(
                "w-full h-full flex flex-row items-center gap-3 truncate",
                (addressError || tokenIdError) && "animate-shake"
            )}
            onSubmit={handleSubmit}
        >
            <div className="h-full flex flex-col justify-center flex-1 border-r border-gray-300">
                <SearchBarInput
                    value={address}
                    onChange={handleChangeContractAddress}
                    error={addressError ? "Invalid contract address" : ""}
                    onKeyUp={handleKeyUp}
                    placeholder="Contract Address (0x...)"
                    autoFocus
                />
                <Divider />
                <SearchBarInput
                    value={tokenId}
                    onChange={handleChangeTokenId}
                    error={tokenIdError ? "Invalid token ID" : ""}
                    onKeyUp={handleKeyUp}
                    placeholder="Token ID (12345)"
                />
            </div>
            <Button
                type="submit"
                size="small"
                disabled={isLoading}
                className="w-12 md:w-20"
            >
                {isLoading ? <Loader variant="light" /> : "Go"}
            </Button>
        </form>
    );
}

interface SearchBarInputProps extends HTMLProps<HTMLInputElement> {
    error: string;
}
function SearchBarInput({
    error,
    className,
    ...props
}: SearchBarInputProps): JSX.Element {
    return (
        <div
            className={clsx(
                "pl-3 flex-1 flex flex-col gap-1 justify-center",
                className
            )}
        >
            <input
                className="text-sm font-medium placeholder-gray-400 focus:outline-none"
                {...props}
            />
            {error && <div className="text-error-500 text-xs">{error}</div>}
        </div>
    );
}
