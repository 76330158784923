import superjson from "superjson";

export function stringify<T = unknown>(data: T): string {
    return superjson.stringify(data);
}

export function stringifyCast<T>(data: T): T {
    return superjson.stringify(data) as T;
}

export function parse<T>(value: string): T {
    return superjson.parse(value);
}
