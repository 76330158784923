import { MutatorCallback } from "swr";
import { parse } from "./json";

export const fetcher = async (
    input: RequestInfo | URL,
    init?: RequestInit | undefined
) => {
    const res = await fetch(input, init);
    const text = await res.text();

    if (!res.ok) throw new Error(text);

    return parse(text);
};

type Method = "POST" | "PATCH" | "PUT" | "DELETE";

export function createMutator<Response, Input = never>(
    method: Method = "POST"
) {
    return async function mutator(url: string, { arg }: { arg: Input }) {
        const res = await fetch(url, {
            method,
            headers: { "Content-Type": "application/json" },
            body: arg ? JSON.stringify(arg) : undefined,
        });

        if (!res.ok) throw new Error(await res.text());
        if (res.status === 204) return null;
        return parse<Response>(await res.text());
    };
}

export function createMutatorCallback<Response, Input = never>(
    url: string,
    method: Method,
    arg?: Input
): MutatorCallback<Response> {
    return async function mutatorCallback() {
        const res = await fetch(url, {
            method,
            headers: { "Content-Type": "application/json" },
            body: arg ? JSON.stringify(arg) : undefined,
        });

        if (!res.ok) throw new Error(await res.text());

        if (res.status === 204) return undefined;

        return parse<Response>(await res.text());
    };
}
