import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useState,
} from "react";
import { track } from "client/lib/analytics";
import * as Portal from "@radix-ui/react-portal";
import SearchBar from "components/SearchBar";

type ToggleSearchFunc = (location: string) => void;

type SearchContextInterface = {
    openSearch: ToggleSearchFunc;
};

const SearchContext = createContext<SearchContextInterface>({
    openSearch: (_: string) => {
        throw new Error("Not yet initialized");
    },
});

export function SearchProvider({
    children,
}: PropsWithChildren<unknown>): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);

    const openSearch = useCallback(
        (location: string) => {
            setIsOpen(true);
            track("search:open", { location });
        },
        [setIsOpen]
    );

    const closeSearch = useCallback(() => {
        setIsOpen(false);
        track("search:close", { location: "SearchBar" });
    }, [setIsOpen]);

    return (
        <SearchContext.Provider value={{ openSearch }}>
            {children}
            {isOpen && (
                <Portal.Root>
                    <div
                        className="bg-black/50 fixed inset-0 cursor-pointer z-30 overflow-hidden"
                        onClick={closeSearch}
                    />
                    <div className="z-40 absolute top-5 left-1/2 transform -translate-x-1/2 w-4/5 md:w-2/3 xl:w-2/5">
                        <SearchBar onClose={closeSearch} />
                    </div>
                </Portal.Root>
            )}
        </SearchContext.Provider>
    );
}

export default function useSearch(): SearchContextInterface {
    return useContext(SearchContext);
}
