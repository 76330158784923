import { useSIWE } from "connectkit";
import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from "react";
import useSWRImmutable from "swr/immutable";

type IsSessionReadyContextInterface = boolean;

const IsSessionReadyContext =
    createContext<IsSessionReadyContextInterface>(false);

interface SiweSessionResponse {
    address?: string;
    chainId?: number;
}

// This fetcher doesn't use superjson like this default one
const fetcher = async (
    input: RequestInfo | URL,
    init?: RequestInit | undefined
) => {
    const res = await fetch(input, init);
    if (!res.ok) throw new Error(await res.text());
    return await res.json();
};

export function IsSessionReadyProvider({
    children,
}: PropsWithChildren<unknown>): JSX.Element {
    const [isSessionReady, setIsSessionReady] = useState(false);

    const { data: sessionData } = useSWRImmutable<SiweSessionResponse>(
        "/api/siwe/session",
        {
            fetcher,
        }
    );

    const { data: siweData } = useSIWE();

    const sessionFetched =
        sessionData && sessionData.address === siweData?.address;

    useEffect(() => {
        if (sessionFetched) {
            setIsSessionReady(true);
        }
    }, [sessionFetched]);

    return (
        <IsSessionReadyContext.Provider value={isSessionReady}>
            {children}
        </IsSessionReadyContext.Provider>
    );
}

export default function useIsSessionReady(): IsSessionReadyContextInterface {
    return useContext(IsSessionReadyContext);
}
