import {
    Close,
    Root,
    ToastProps as RadixToastProps,
} from "@radix-ui/react-toast";
import { clsx } from "clsx";
import Icon from "components/icon";
import { ReactNode } from "react";

export { Provider, Viewport } from "@radix-ui/react-toast";

export interface ToastProps extends RadixToastProps {
    content: ReactNode;
}

export function ToastContainer({
    content,
    className,
    ...props
}: ToastProps): JSX.Element {
    const mergedClassName = clsx(
        "p-4 flex relative mt-2 first:mt-0",
        "fixed bottom-4 inset-x-4 w-auto sm:top-4 sm:right-4 sm:left-auto sm:bottom-auto sm:w-full sm:max-w-sm shadow-lg rounded-lg",
        "bg-white",
        "radix-state-open:animate-toast-slide-in-bottom sm:radix-state-open:animate-toast-slide-in-right",
        "radix-state-closed:animate-toast-hide",
        "radix-swipe-direction-right:radix-swipe-end:animate-toast-swipe-out-x",
        "radix-swipe-direction-right:translate-x-radix-toast-swipe-move-x",
        "radix-swipe-direction-down:radix-swipe-end:animate-toast-swipe-out-y",
        "radix-swipe-direction-down:translate-y-radix-toast-swipe-move-y",
        "radix-swipe-cancel:translate-x-0 radix-swipe-cancel:duration-200 radix-swipe-cancel:ease-[ease]",
        "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75",
        className
    );

    return (
        <Root className={mergedClassName} {...props}>
            <div className="flex-1">{content}</div>
            <div className="absolute top-2 right-3">
                <Close>
                    <Icon name="cross" className="w-3 h-3" />
                </Close>
            </div>
        </Root>
    );
}
