import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react";

type Size = "small" | "medium";
export type ButtonVariant =
    | "primary"
    | "secondary"
    | "light"
    | "ghost"
    | "loading"
    | "success"
    | "error";

export type ButtonProps = DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> & {
    size?: Size;
} & (
        | {
              variant?: Exclude<ButtonVariant, "ghost">;
              dark?: false;
          }
        | {
              variant: "ghost";
              dark: true;
          }
    );

function getVariantClassNames(variant: ButtonVariant, dark: boolean): string {
    switch (variant) {
        case "primary":
            return "text-gray-25 bg-gray-900 border border-gray-900";
        case "secondary":
            return "text-gray-900 bg-white border border-gray-300";
        case "light":
            return "bg-gray-200 text-gray-900";
        case "ghost":
            return dark
                ? "hover:bg-white/10 data-[state=open]:bg-white/10 text-gray-25"
                : "hover:bg-gray-200 data-[state=open]:bg-gray-200 text-gray-900";
        case "loading":
            return "bg-warning-100 text-warning-700 pointer-events-none cursor-wait";
        case "success":
            return "bg-success-100 text-success-700 pointer-events-none cursor-default";
        case "error":
            return "bg-error-100 text-error-700 pointer-events-none cursor-default";
    }
}

function getSizeClassNames(size: Size): string {
    switch (size) {
        case "small":
            return "h-9 px-2 md:px-3 text-xs md:text-sm font-medium";
        case "medium":
            return "h-14 px-4 text-md font-medium";
    }
}

export default forwardRef<HTMLButtonElement, ButtonProps>(function Button(
    {
        children,
        className,
        variant = "primary",
        dark = false,
        size = "medium",
        ...props
    }: ButtonProps,
    ref
): JSX.Element {
    const baseClassName = `${getSizeClassNames(size)} ${getVariantClassNames(
        variant,
        dark
    )} relative disabled:opacity-50 disabled:pointer-events-none flex flex-row items-center justify-center rounded-full cursor-pointer`;
    const finalClassName = baseClassName + (className ? ` ${className}` : "");

    return (
        <button className={finalClassName} ref={ref} {...props}>
            {children}
        </button>
    );
});
