import { clsx } from "clsx";
import DropdownMenu, { Item } from "components/DropdownMenu";
import Icon from "components/icon";
import { Dispatch, SetStateAction } from "react";
import { SearchType } from "./types";

interface SearchTypeSelectorProps {
    searchType: SearchType;
    setSearchType: Dispatch<SetStateAction<SearchType>>;
}

export default function SearchTypeSelector({
    searchType: selectedSearchType,
    setSearchType,
}: SearchTypeSelectorProps): JSX.Element {
    return (
        <DropdownMenu
            trigger={
                <button className="h-full w-24 md:w-32 border-r border-gray-300 rounded-tl-full rounded-bl-full flex items-center justify-between gap-1 pl-3 md:pl-6 pr-2">
                    <span className="text-xs md:text-sm text-gray-900 flex-1">
                        {selectedSearchType}
                    </span>
                    <Icon name="chevron-down" />
                </button>
            }
            align="end"
            sideOffset={10}
            className="isolate z-40"
        >
            <div className="bg-white rounded-lg border border-gray-300 flex flex-col items-start overflow-hidden">
                {Object.values(SearchType).map((searchType) => (
                    <Item
                        key={searchType}
                        onSelect={() => setSearchType(searchType)}
                        className={clsx(
                            "p-3 text-center w-full text-xs md:text-sm text-gray-500 focus:relative focus:outline-none focus-visible:z-40 focus-visible:ring hover:cursor-pointer hover:text-gray-900",
                            searchType === selectedSearchType &&
                                "text-gray-800 bg-gray-100"
                        )}
                    >
                        {searchType}
                    </Item>
                ))}
            </div>
        </DropdownMenu>
    );
}
