import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";
import { jsNumberStringValidator } from "./zod/validators.mjs";

export const env = createEnv({
    client: {
        NEXT_PUBLIC_CHAIN_ID: jsNumberStringValidator,
        NEXT_PUBLIC_CHAIN_ID_L2: jsNumberStringValidator,
        NEXT_PUBLIC_MIXPANEL_TOKEN: z.string().min(1),
        NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID: z.string().min(1),
    },
    server: {
        FARCASTER_MNEMONIC: z.string().min(1),
        JSON_RPC_PROVIDER_URL: z.string().url(),
        JSON_RPC_PROVIDER_URL_L2: z.string().url(),
        OPENSEA_API_KEY: z.string().min(1),
        QSTASH_TOKEN: z.string().min(1),
        REDIS_URL: z.string().url(),
        REMINT_FEED_CHANNEL_ID: z.string().min(1).optional(),
        SLACK_BOT_TOKEN: z.string().min(1).optional(),
        SLACK_SIGNING_SECRET: z.string().min(1).optional(),
        TWITTER_CLIENT_ID: z.string().min(1),
        TWITTER_CLIENT_SECRET: z.string().min(1),
        TWITTER_OAUTH_ENCRYPTION_IV: z.string().length(32),
        TWITTER_OAUTH_ENCRYPTION_KEY: z.string().length(32),
        TWITTER_OAUTH_STATE: z.string().min(1),
        TWITTER_OAUTH_REDIRECT_HOSTNAME: z.string().url(),
    },
    runtimeEnv: {
        NEXT_PUBLIC_CHAIN_ID: process.env.NEXT_PUBLIC_CHAIN_ID,
        NEXT_PUBLIC_CHAIN_ID_L2: process.env.NEXT_PUBLIC_CHAIN_ID_L2,
        NEXT_PUBLIC_MIXPANEL_TOKEN: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
        NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID:
            process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID,
        FARCASTER_MNEMONIC: process.env.FARCASTER_MNEMONIC,
        JSON_RPC_PROVIDER_URL: process.env.JSON_RPC_PROVIDER_URL,
        JSON_RPC_PROVIDER_URL_L2: process.env.JSON_RPC_PROVIDER_URL_L2,
        OPENSEA_API_KEY: process.env.OPENSEA_API_KEY,
        QSTASH_TOKEN: process.env.QSTASH_TOKEN,
        REDIS_URL: process.env.REDIS_URL,
        REMINT_FEED_CHANNEL_ID: process.env.REMINT_FEED_CHANNEL_ID,
        SLACK_BOT_TOKEN: process.env.SLACK_BOT_TOKEN,
        SLACK_SIGNING_SECRET: process.env.SLACK_SIGNING_SECRET,
        TWITTER_CLIENT_ID: process.env.TWITTER_CLIENT_ID,
        TWITTER_CLIENT_SECRET: process.env.TWITTER_CLIENT_SECRET,
        TWITTER_OAUTH_ENCRYPTION_IV: process.env.TWITTER_OAUTH_ENCRYPTION_IV,
        TWITTER_OAUTH_ENCRYPTION_KEY: process.env.TWITTER_OAUTH_ENCRYPTION_KEY,
        TWITTER_OAUTH_REDIRECT_HOSTNAME:
            process.env.TWITTER_OAUTH_REDIRECT_HOSTNAME,
        TWITTER_OAUTH_STATE: process.env.TWITTER_OAUTH_STATE,
    },
});
