/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

(window ).__rewriteFramesAssetPrefixPath__ = '';

import { GLOBAL_OBJ } from '@sentry/utils';

/* eslint-disable no-constant-condition */

const globalObj = GLOBAL_OBJ ;

globalObj.SENTRY_RELEASE = { id: '19e3eb636bb7fbc6640d27f1d803cf9c8f18c1ac' };

// Enable module federation support (see https://github.com/getsentry/sentry-webpack-plugin/pull/307)
if ('homage-xyz') {
  const key = 'homage-xyz' ? 'homage-xyz@homage-xyz' : 'homage-xyz';
  globalObj.SENTRY_RELEASES = globalObj.SENTRY_RELEASES || {};
  globalObj.SENTRY_RELEASES[key] = { id: '19e3eb636bb7fbc6640d27f1d803cf9c8f18c1ac' };
}

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    environment: process.env.NEXT_PUBLIC_VERCEL_ENV || "development",
    dsn:
        SENTRY_DSN ||
        "https://22de0c6be53146169c8cbd2f7fbdc16e@o1143711.ingest.sentry.io/4505359578365952",
    tracesSampleRate: 1.0,
});
