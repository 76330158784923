import { identify, reset, track } from "client/lib/analytics";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { IsSessionReadyProvider } from "context/is-session-ready";
import { SearchProvider } from "context/search";
import { ToastProvider } from "context/toast";
import { fetcher } from "lib/api";
import { env } from "lib/env.mjs";
import { siweClient } from "lib/siwe/client";
import { addressValidator } from "lib/zod/validators.mjs";
import { DefaultSeo as NextDefaultSeo } from "next-seo";
import { AppProps } from "next/app";
import Head from "next/head";
import NextNProgress from "nextjs-progressbar";
import { useCallback } from "react";
import { SWRConfig } from "swr";
import { createConfig, WagmiConfig } from "wagmi";
import { mainnet, optimism } from "wagmi/chains";
import "client/styles/globals.css";

const config = createConfig(
    getDefaultConfig({
        appName: "Homage",
        chains: [optimism, mainnet],
        walletConnectProjectId: env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID,
    })
);

export default function App({ Component, pageProps }: AppProps): JSX.Element {
    const handleConnect = useCallback(({ address, connectorId }) => {
        identify(addressValidator.parse(address));
        track("event:connect-wallet", { connector: connectorId });
    }, []);

    const handleSignIn = useCallback(() => track("event:sign-in"), []);

    const handleDisconnect = useCallback(() => {
        track("event:disconnect-wallet");
        reset();
    }, []);

    const handleSignOut = useCallback(() => {
        track("event:sign-out");
        reset();
    }, []);

    return (
        <SWRConfig value={{ fetcher }}>
            <WagmiConfig config={config}>
                <siweClient.Provider
                    onSignIn={handleSignIn}
                    onSignOut={handleSignOut}
                    signOutOnNetworkChange={false}
                >
                    <ConnectKitProvider
                        onConnect={handleConnect}
                        onDisconnect={handleDisconnect}
                        options={{
                            overlayBlur: 20,
                        }}
                    >
                        <IsSessionReadyProvider>
                            <ToastProvider>
                                <SearchProvider>
                                    <Head>
                                        <link
                                            rel="shortcut icon"
                                            href="/favicon.ico"
                                        />
                                    </Head>
                                    <DefaultSeo />
                                    <NextNProgress
                                        color="rgb(31 41 55)"
                                        startPosition={0.3}
                                        stopDelayMs={200}
                                        height={3}
                                        showOnShallow={true}
                                        options={{ showSpinner: false }}
                                    />
                                    <Component {...pageProps} />
                                </SearchProvider>
                            </ToastProvider>
                        </IsSessionReadyProvider>
                    </ConnectKitProvider>
                </siweClient.Provider>
            </WagmiConfig>
        </SWRConfig>
    );
}

const DefaultSeo = () => {
    return (
        <NextDefaultSeo
            titleTemplate="Homage | %s"
            defaultTitle="Homage"
            description="Remint your favorite NFTs"
            canonical="https://homage.xyz"
            openGraph={{
                type: "website",
                description: "Remint your favorite NFTs",
                url: "https://www.homage.xyz",
                site_name: "Homage",
                images: [
                    {
                        url: "https://www.homage.xyz/og.png",
                        width: 1200,
                        height: 600,
                        alt: "Homage Logo",
                    },
                ],
            }}
            twitter={{
                cardType: "summary_large_image",
            }}
        />
    );
};
