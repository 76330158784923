import {
    Provider,
    Viewport,
    ToastProps,
    ToastContainer,
} from "components/Toast";
import { createContext, useCallback, useContext, useState } from "react";

interface ToastContextInterface {
    toast: (toastProps: ToastProps) => void;
}

const initialContext: ToastContextInterface = {
    toast: (_: ToastProps) => {},
};

const ToastContext = createContext<ToastContextInterface>(initialContext);

interface ToastProviderProps {
    children: JSX.Element;
}

export function ToastProvider({ children }: ToastProviderProps): JSX.Element {
    const [toasts, setToasts] = useState<ToastProps[]>([]);

    const toast = useCallback(
        (toast) => {
            setToasts([...toasts, toast]);
        },
        [toasts, setToasts]
    );

    return (
        <ToastContext.Provider
            value={{
                toast,
            }}
        >
            <Provider>
                <div className="z-50 fixed right-2 top-2">
                    <Viewport />
                </div>
                {toasts.map((toast, i) => (
                    <ToastContainer key={i} {...toast} />
                ))}
                {children}
            </Provider>
        </ToastContext.Provider>
    );
}

export default function useToast(): ToastContextInterface {
    return useContext(ToastContext);
}
