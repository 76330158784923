import { env } from "lib/env.mjs";
import { Address } from "lib/zod/types";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { JSONValue } from "superjson/src/types";

type Dict = Record<string, JSONValue>;

mixpanel.init(env.NEXT_PUBLIC_MIXPANEL_TOKEN, { debug: true });

export function identify(address: Address): void {
    mixpanel.identify(address);
    mixpanel.people.set("address", address);
}

export function track(event: string, properties?: Dict): void {
    mixpanel.track(event, properties);
}

export function reset(): void {
    mixpanel.reset();
}

export function usePageview(name: string, properties?: Dict): void {
    // Fire the tracking event only on the initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => void track(`pageview:${name}`, properties), []);
}
