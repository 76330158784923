import { parse } from "lib/json";
import { addressValidator } from "lib/zod/validators.mjs";
import { useRouter } from "next/router";
import { EnsResolveResponse } from "pages/api/ens/resolve/[ensName].page";
import { ChangeEvent, KeyboardEvent, useCallback, useState } from "react";
import { SearchForm } from "./SearchForm";
import { SearchProps } from "./types";

export default function AccountSearch({ onClose }: SearchProps): JSX.Element {
    const router = useRouter();
    const [value, setValue] = useState("");
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value.trim();
            if (value === newValue) return;
            setValue(newValue);
            setError(false);
        },
        [value]
    );

    const handleKeyUp = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (isLoading) return;
            if (event.key === "Escape") onClose();
        },
        [onClose, isLoading]
    );

    const handleSubmit = useCallback(
        async (event) => {
            event.preventDefault();

            if (isLoading) return;
            setIsLoading(true);

            // If the input is an address, use it to navigate
            const parsed = addressValidator.safeParse(value);
            if (parsed.success) {
                void router.push(`/user/${parsed.data}`);
                return onClose();
            }

            if (!value.includes(".")) {
                setIsLoading(false);
                setError(true);
                return;
            }

            const response = await fetch(`/api/ens/resolve/${value}`);
            if (!response.ok) {
                // If the request fails, assume the value is an ENS and navigate
                void router.push(`/user/${value}`);
                return onClose();
            }

            const { address } = parse<EnsResolveResponse>(
                await response.text()
            );
            if (address) {
                void router.push(`/user/${address}`);
                return onClose();
            }

            setError(true);
            setIsLoading(false);
        },
        [router, isLoading, value, onClose]
    );

    return (
        <SearchForm
            value={value}
            onChange={handleChange}
            label="ENS or address"
            onSubmit={handleSubmit}
            placeholder="coryhaber.eth, 0x..."
            error={error ? "Input is not a valid ENS or address" : ""}
            isLoading={isLoading}
            handleKeyUp={handleKeyUp}
            disabled={isLoading || value.length === 0}
        />
    );
}
